import * as React from "react";
import { Typography } from "@material-ui/core";
import { Link } from "gatsby-theme-material-ui";
import Layout from "../components/layout";
import Seo from "../components/seo";

const About = () => (
  <Layout>
    <Seo title="About" />
    <Typography variant="h4" component="h1">
      About
    </Typography>
    <Typography>About me: Nothing is written here yet.</Typography>
    <Typography>
      About the site: Built with{" "}
      <Link to="https://www.gatsbyjs.com/">Gatsby</Link> and{" "}
      <Link to="https://material-ui.com/">Material-UI</Link>. Hosted on{" "}
      <Link to="https://pages.cloudflare.com/">Cloudflare Pages</Link>.
    </Typography>
  </Layout>
);

export default About;
